<template>
  <section class="bg_color">
    <h2>モーター一覧</h2>
    <section class="inner">
      <div class="ribon_outline">
        <div class="ttl_ribon">
          <p class="ribon_inner"><Today /></p>
        </div>
      </div>
      <h3 class="ttl_main"><LastRace /></h3>
      <p class="tyu">※獲得モグ―の更新は、レース終了後約5分後に行われます。</p>
      <!--オーナー登録したモーター-->
      <div class="box_lst slide registered">
        <h4 class="ttl_lst">
          <div class="inner">
            <figure class="registered__icon">
              <img
                :src="info.icon ? info.icon : Icon[0].img"
                height=""
                alt=""
              />
            </figure>
            <div class="registered__txt">
              {{ info.nickname }}さんが<br /><span class="registered__moter"
                >オーナー登録したモーター</span
              >
            </div>
          </div>
        </h4>
        <div class="slide__wrap" v-if="selectedMotor && selectedMotor.length">
          <Splide :options="options">
            <SplideSlide v-for="val in selectedMotor" :key="val">
              <div>
                <div class="box_moter_entry">
                  <div class="moter_dtl">
                    <dl>
                      <dt>モーター番号</dt>
                      <dd>{{ val.mno }}</dd>
                      <dt>獲得モグー(累計)</dt>
                      <dd>
                        <span class="mog">{{
                          addComma(Number(val.current_mog))
                        }}</span>
                      </dd>
                      <dt>登録日</dt>
                      <dd>
                        <!-- 2021<span class="small">年</span>1<span class="small"
                            >月</span
                          >1<span class="small">日</span> -->
                        {{ moment(val.selected_date) }}
                      </dd>
                      <dt>今節の使用者</dt>
                      <dd class="new">
                        {{ val.curret_racer_name }}({{ val.curret_racer_kyu }})
                      </dd>
                      <dt class="getmog">今節獲得モグー</dt>
                      <dd class="getmog">
                        <span class="mog">{{
                          addComma(Number(val.current_section_mog))
                        }}</span>
                      </dd>
                    </dl>
                    <figure>
                      <img :src="motorImg[val.mno]" width="" height="" alt="" />
                    </figure>
                  </div>
                  <div class="box_lnk">
                    <router-link
                      :to="{ name: 'MotorDtl', params: { id: val.mno } }"
                      class="btn_dtl"
                      >モーター履歴を見る</router-link
                    >
                  </div>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div class="no_select" v-else>
          <p>現在登録しているモーターはありません</p>
        </div>
      </div>

      <!--モーターを登録して、オーナーになろう！-->
      <div class="box_lst slide moter_lst">
        <h4 class="ttl_lst">
          <div class="inner">
            <span class="moter_icon"
              >モーターを登録して、<br
                class="visible_sp"
              />オーナーになろう！</span
            >
            <span class="moter_lst__catch"
              ><img
                src="@/assets/images/parts/catch_moter.svg"
                alt="モーターは６機まで選択できます。"
            /></span>
          </div>
        </h4>
        <div class="sort">
          <p class="sort__note">
            <!-- ※2023/24seasonのモーター登録は締め切りました。<br /> -->
            ※一度登録したモーターは来年度まで変更できません。<br />
            ※表示されている獲得モグ―はモーターの累積獲得モグ―であり、登録後は0モグ―からの加算となります。
          </p>
          <div class="sort__block">
            <p class="sort__title">並び替え</p>
            <ul class="sort__list">
              <li>
                <a href="#" class="trans" v-on:click.prevent="mnoSort">
                  <p class="sort__item">モーター番号順&ensp;</p>
                </a>
              </li>
              <li>
                <a href="#" class="trans" v-on:click.prevent="mogSort">
                  <p class="sort__item">獲得モグー順&ensp;</p>
                </a>
              </li>
              <li>
                <a href="#" class="trans" v-on:click.prevent="m2renSort">
                  <p class="sort__item">2連対率</p>
                </a>
              </li>
              <!-- <li>
                <a href="#" class="trans" v-on:click.prevent="ratioSort">
                  <p class="sort__item">勝 率</p>
                </a>
              </li> -->
              <li>
                <a href="#" class="trans" v-on:click.prevent="winSort">
                  <p class="sort__item">優勝回数順&ensp;</p>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul class="lst lst--moter">
          <li v-for="v in motorList" v-bind:key="v">
            <div class="box_thum">
              <figure class="round">
                <img :src="motorImg[v.mno]" width="" height="" alt="" />
              </figure>
              <p class="name_large">
                <span class="small">モーター番号</span>{{ Number(v.mno) }}
              </p>
            </div>
            <div class="box_dtl">
              <p class="ttl none">獲得モグー</p>
              <p class="txt point">{{ addComma(Number(v.mog)) }}</p>
            </div>
            <div class="box_dtl box_data">
              <div class="lst--moter__item first">
                <p class="lst--moter__ttl">優勝回数</p>
                <p class="lst--moter__result">
                  <span class="num">{{ v.win_count }}</span
                  >回
                </p>
              </div>
              <!-- <div class="lst--moter__item rate01">
                <p class="lst--moter__ttl">勝率</p>
                <p class="lst--moter__result">
                  <span class="num">{{ orgFloor(v.win_ratio, 100) }}</span
                  >％
                </p>
              </div> -->
              <div class="lst--moter__item rate02">
                <p class="lst--moter__ttl">２連対率</p>
                <p class="lst--moter__result">
                  <span class="num">{{ orgFloor(v.m2ren, 10) }}</span
                  >％
                </p>
              </div>
            </div>
            <div class="lst--moter__btn">
              <router-link
                :to="{ name: 'MotorDtl', params: { id: v.mno } }"
                class="btn_dtl"
                >モーター履歴を見る</router-link
              >
            </div>
            <div class="lst--moter__btn">
              <a
                href="#"
                class="btn_dtl btn_register"
                v-on:click.prevent="openModal(v.mno)"
                >登録する</a
              >
            </div>
          </li>
        </ul>

        <transition name="fade" v-show="isShow" @close="closeModal">
          <motorRegist :selectMotor="selectNum" />
        </transition>
      </div>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import LastRace from "@/components/LastRaceInfo.vue";
import Today from "@/components/Today.vue";
import motorRegist from "@/components/motorRegist.vue";
import "@/assets/css/carousel.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { mapGetters } from "vuex";

export default {
  name: "SelectMotor",
  data() {
    return {
      isShow: false,
      selectNum: "",
      options: {
        rewind: true,
        classes: {
          arrows: "splide__arrows mog-arrows",
          arrow: "splide__arrow mog-arrow",
          prev: "splide__arrow--prev mog-prev",
          next: "splide__arrow--next mog-next",
        },
        pagination: "splide__pagination mog-pagination",
        page: "splide__pagination__page mog-page",
        arrowPath: "M 0 0 L 25 25 L 0 50",
      },
    };
  },
  components: {
    LastRace,
    Today,
    motorRegist,
    Splide,
    SplideSlide,
  },
  mixins: [Mixin],
  beforeMount: function () {
    this.$store.dispatch("reqSelectedMotor");
    this.$store.dispatch("reqMotorList");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("reqSelectedMotor");
    }, 15000);
  },
  computed: {
    ...mapGetters({
      info: "getMemberInfo",
      motorList: "getMotorInfo",
      selectedMotor: "getSeletedMotor",
      Icon: "getIcon",
      motorImg: "getMotorImg",
    }),
  },
  methods: {
    orgFloor(val, base) {
      return Math.floor(val * base) / base;
    },
    mnoSort() {
      this.motorList.sort(function (a, b) {
        return a.mno - b.mno;
      });
    },
    mogSort() {
      this.motorList.sort(function (a, b) {
        return b.mog - a.mog;
      });
    },
    m2renSort() {
      this.motorList.sort(function (a, b) {
        return b.m2ren - a.m2ren;
      });
    },
    ratioSort() {
      this.motorList.sort(function (a, b) {
        return b.win_ratio - a.win_ratio;
      });
    },
    winSort() {
      this.motorList.sort(function (a, b) {
        return b.win_count - a.win_count;
      });
    },
    openModal(data) {
      this.isShow = true;
      this.selectNum = data;
    },
    closeModal() {
      this.isShow = false;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style lang="css" src="@/assets/css/style_select.css" scoped></style>
<style>
.splide {
  margin: auto;
}

.mog-arrow {
  background: transparent;
  opacity: 1;
}

.mog-prev {
  left: 4vw;
}

.mog-next {
  right: 4vw;
}

.splide__pagination__page {
  background: #909db3;
  margin-right: 10px;
  margin-left: 10px;
}

.splide__pagination__page.is-active {
  background: #0a2961;
  transform: scale(1);
}

.splide__arrow svg {
  fill: none;
  stroke: #0a2961;
  stroke-width: 2px;
  height: 11vw;
  width: 11vw;
}
.no_select p {
  padding: 3%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .mog-prev {
    left: -0.5em;
  }

  .mog-next {
    right: -0.5em;
  }
}
</style>
