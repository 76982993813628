<template>
  <div>
    <div class="modal_inner modal_inner--motor">
      <div class="box_select_motor">
        <p class="select_motor_txt">
          一度登録したモーターは<br
            class="hidden-md"
          />来年度のモーター入替まで変更できません。<br />このモーターを登録しますか？
        </p>
        <!-- <div v-if="info.user_type === '9'">
          <p class="textarea">
            【コメント】<br />
            <textarea cols="30" rows="10" v-model="send.comment"></textarea>
          </p>
          <p class="pickarea">
            【ピックアップモーター】<br /><span
              >※ピックアップは1機のみの選択となります。</span
            ><br />
            <input
              id="pickup"
              type="checkbox"
              value="Jack"
              v-model="send.pickup"
              true-value="1"
              false-value="0"
            />
            <label for="pickup" class="checkbox01">PICKUPモータにする</label>
          </p>
        </div> -->
        <div class="box_btn_motor">
          <a
            href="#"
            class="btn_dtl btn_register"
            v-on:click.prevent="registMotor(send)"
            >登録する</a
          >
          <a href="#" class="btn_dtl btn_cancel" v-on:click.prevent="close()">
            キャンセル
          </a>
        </div>
        <span class="alert" v-show="!result.regist">{{ result.message }}</span>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "motorRegist",
  props: ["selectMotor"],
  data() {
    return {
      send: {
        mno: this.selectMotor,
        comment: "",
        pickup: "",
      },
      result: {
        regist: true,
        message: "",
      },
    };
  },
  created: function () {},
  computed: {
    ...mapGetters({
      info: "getMemberInfo",
    }),
  },
  methods: {
    registMotor(val) {
      this.$store.dispatch("registMotor", val).then((response) => {
        if (!response.data.result) {
          this.result.regist = false;
          this.result.message = response.data.message;
        } else {
          this.$emit("close");
          this.result.regist = true;
          this.$store.dispatch("reqSelectedMotor");
        }
      });
    },
    close() {
      this.result.regist = true;
      this.result.message = "";
      this.$emit("close");
    },
  },
  watch: {
    selectMotor: function (newVal) {
      this.send.mno = newVal;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
<style lang="css" src="@/assets/css/style_select.css" scoped></style>
<style scoped>
.alert {
  color: #ed7a9c;
  display: inline-block;
  padding: 3%;
}
.textarea {
  margin-bottom: 3%;
}
textarea {
  width: 100%;
}
.pickarea {
  margin-bottom: 5%;
}
.pickarea span {
  font-size: 14px;
}
input[type="checkbox"] {
  display: none;
}
.checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
}
.checkbox01::before {
  background: #fff;
  border: 1px solid #231815;
  content: "";
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.checkbox01::after {
  border-right: 3px solid #ed7a9c;
  border-bottom: 3px solid #ed7a9c;
  content: "";
  display: block;
  height: 15px;
  left: 10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 8px;
}
input[type="checkbox"]:checked + .checkbox01::after {
  opacity: 1;
}
</style>
